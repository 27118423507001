import { Alert, Col, Form, Input, Row, Spin, Switch, TreeSelect, message } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetCategoryListByTypeKeyQuery } from "../../redux/slice/category/categoryApiSlice";
import { cleanFormValues } from "../../utils/cleanValues";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import DashboardActionBar from "../DashboardActionBar";
const { TreeNode } = TreeSelect;
const FaqsForm = ({
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  data,
}) => {
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const [answer, setAnswer] = useState(
    isUpdateForm && data.dataById && data.dataById.type_description
  );

  let category_type_key = "faqs";
  const {
    data: categoriesByType,
    isLoading: categoryListLoading,
    isError: categoryListError,
  } = useGetCategoryListByTypeKeyQuery({
    type_key: category_type_key,
  });

  const loading = selectedForm.loading || data.loading || categoryListLoading;

  if (categoryListError) {
    message.error("Error on loading category list");
  }



  

  let categoriesOptions = categoriesByType?.map((itm) => {
    const { id, title, children } = itm;
    return {
      value: id,
      label: title,
      ...(children && {
        children: children?.map((child) => {
          const { id, title, children } = child;
          return {
            value: id,
            label: title,
            ...(children && {
              children: children?.map((gChild) => {
                const { id, title } = gChild;
                return {
                  value: id,
                  label: title,
                };
              }),
            }),
          };
        }),
      }),
    };
  });

  if (isUpdateForm) {
    setFieldsValue({
      question: data.dataById?.question,
      url_title: data.dataById?.url_title,
      slug: data.dataById?.slug,
      title: data.dataById?.type_title,
      type_key: data.dataById?.type_key,
      status: data.dataById?.status === 1 ? true : false,
      answer: data.dataById?.answer,
      categories: data.dataById?.faq_cats?.map((a) => a),
      meta_title: data.dataById?.meta_title,
      meta_keywords: data.dataById?.meta_keywords,
      meta_description: data.dataById?.meta_description,
    });
  }

  const faqsAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      let cleanedValues = cleanFormValues(values);

      if (action === "add") {
        createEntity({
          ...cleanedValues,
          status: cleanedValues.status ? 1 : 0,
        });
      } else if (action === "addandexit") {
        createEntity({
          ...cleanedValues,
          status: cleanedValues.status ? 1 : 0,
        })
          .unwrap()
          .then(() => navigate("/faqs"))
          .catch();
      } else if (action === "update") {
        updateEntityInfo({
          formData: {
            ...cleanedValues,
            status: cleanedValues.status ? 1 : 0,
          },
          id: params.id,
        })
          .unwrap()
          .then((data) => console.log(data))
          .catch();
      }
    });
  };

  const addNewEntry = (e) => {
    faqsAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    faqsAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    faqsAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setAnswer("");
  };

  const actionBarOptions = {
    titleToAdd: "Add FAQ",
    titleToUpdate: "Update FAQ",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update FAQ",
    taskCompleteAddMessage: "FAQ is added!",
    taskCompleteUpdateMessage: "FAQ is updated!",
    discardLink: "faqs",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    addNewEntryAndExistToList,
  };

  return (
    <Form
      form={form}
      size="large"
      layout="vertical"
      className="custom-form"
      wrapperCol={{
        flex: 1,
      }}
    >
      <DashboardActionBar {...{ actionBarOptions }} />

      {selectedForm?.error?.errors && (
        <Alert
          className="custom-alert"
          message="Error"
          showIcon
          description={[
            <ul>
              {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                return <li key={idx}>{itm}</li>;
              })}
            </ul>,
          ]}
          type="error"
          closable
        />
      )}

      <Spin spinning={loading} delay={500}>
        <div className="common-module">
          <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
            <Col className="gutter-row" xs={24}>
              <Form.Item
                label="Question"
                name="question"
                rules={[
                  {
                    required: true,
                    message: "question is required",
                  },
                  { min: 3, max: 255, message: "Invalid question" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12} xl={12}>
              <Form.Item
                label="Categories"
                name="categories"
                rules={[
                  {
                    required: true,
                    message: "Category is required",
                  },
                ]}
              >
                <TreeSelect
                  showSearch
                  style={{ width: "100%" }}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Please select"
                  allowClear
                  multiple
                  treeDefaultExpandAll
                >
                  {categoriesOptions?.map((parent) => {
                    const { value, label, children } = parent;
                    return (
                      <TreeNode key={value} value={value} title={label}>
                        {children.length !== 0 &&
                          children?.map((child) => {
                            const { value, label, children } = child;
                            return (
                              <TreeNode key={value} value={value} title={label}>
                                {children.length !== 0 &&
                                  children?.map((gChild) => {
                                    const { value, label } = gChild;
                                    return <TreeNode key={value} value={value} title={label} />;
                                  })}
                              </TreeNode>
                            );
                          })}
                      </TreeNode>
                    );
                  })}
                </TreeSelect>
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12} xl={12}>
              <div className="switch-group">
                <Form.Item label="Status" valuePropName="checked" name="status">
                  <Switch checkedChildren="Publish" unCheckedChildren="Unpublish" />
                </Form.Item>
              </div>
            </Col>
            <Col className="gutter-row" xs={24}>
              <Form.Item label="Answer" name="answer">
                <TextEditor
                  value={answer}
                  initialValue={isUpdateForm && answer}
                  onEditorChange={(e) => {
                    setAnswer(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Spin>
    </Form>
  );
};

export default FaqsForm;
